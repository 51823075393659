export default {
  originalPrice: 'Original price',
  savings: 'Savings',
  shipping: 'Shipping',
  fees: 'Fees',
  total: 'Total',
  taxes: 'Taxes',
  validation: {
    notFilled: 'This field has to be filled.',
    invalidPhoneNumber: 'Invalid phone number',
    invalidTaxId: 'Invalid tax id',
    invalidPassword: 'Password must be minimum 10 symbols',
    invalidEmail: 'Invalid email',
    invalidCvc: 'Please enter a valid CVV code',
  },
};
